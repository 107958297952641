<template>
  <div class="single-website">
    <div class="site-info">
      <div class="site-status"></div>
      <div class="site-name">
        <router-link v-if="website.status === 'active'" :to="'/site/' + website._id">{{ website.siteName }}</router-link>
        <p class="website-name" v-else>{{ website.siteName }}</p>
      </div>
      <div class="site-url">
        <a v-if="website.status === 'active'" :href="siteUrl" target="_blank">{{ siteUrl }}</a>
      </div>
    </div>
    <div v-if="website.status === 'active'" class="site-action">
      <Button class="clear-cache"
              :class="{ 'disable-click': website.clearCacheBtnConf.working }"
              classes="btn-danger"
              name="Clear Cache"
              :show-done-sign="website.clearCacheBtnConf.done"
              :show-loader="website.clearCacheBtnConf.working"
              :with-loader="true"
              height="40px"
              padding="0 1.6rem"
              fontSize="16px"
              @click.native="$emit('clearCache', website._id)" />
      <Button class="wp-admin"
              :class="{ 'disable-click': website.wpAdminBtnConf.working }"
              classes="primary-btn"
              name="WP Admin"
              :show-done-sign="website.wpAdminBtnConf.done"
              :show-loader="website.wpAdminBtnConf.working"
              :with-loader="true"
              height="40px"
              padding="0 1.6rem"
              fontSize="16px"
              @click.native="$emit('wpAdminLogin', website._id)" />
    </div>
    <div v-else class="site-action" style="position: relative">
      <div class="ld-bg" style="left: -40px;">
        <div class="loader" style="border: 3px solid #e4ebff; border-top: 3px solid var(--aurizor-blue);"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/Button";

export default {
  name: "WebsiteList",
  props: ["website"],
  components: {
    Button
  },
  computed: {
    siteUrl() {
      const site = this.website;
      if (site.customDomainIsActive) {
        let proto = "http";
        if (site.sslCertificateIsActive) proto = "https";
        return `${proto}://${site.customDomain}`;
      }
      return `https://${site.websiteUrl}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.single-website {
  width: 100%;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  //cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 10px;
  }

  .site-info {
    display: flex;
    align-items: center;
    .site-status {
      width: 10px;
      height: 10px;
      border-radius: 12px;
      background: #03CD3B;
      margin-right: 16px;
    }
    .site-name {
      width: 177px;
      .website-name {
        color: var(--aurizor-blue);
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
      a {
        color: var(--aurizor-blue);
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
      }
    }
    .site-url {
      width: 400px;
      a {
        font-size: 16px;
        line-height: 22px;
        color: var(--black);
      }
    }
  }

  .site-action {
    display: flex;
    button {
      height: 40px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0125em;
      padding: 0 1.6rem;
    }
    .wp-admin {
      margin-left: 1.6rem;
    }
  }
}
</style>