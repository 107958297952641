<template>
  <div class="single-website">
    <div class="site-info">
      <div class="pack-name">
        <p>{{ info.package.name }}</p>
      </div>
      <div class="invoice-expires">
        <p>Expires at {{ info.invoice.expiresAt }}</p>
      </div>
    </div>
    <div class="site-action">
      <button
          class="btn primary-btn"
          @click="$emit('setupWordPress', [info.invoice._id, info.package._id])">Setup WordPress</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailableToSetupList",
  props: {
    info: Object
  },
}
</script>

<style lang="scss" scoped>
.single-website {
  width: 100%;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  //cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 10px;
  }

  .site-info {
    display: flex;
    align-items: center;
    .site-status {
      width: 10px;
      height: 10px;
      border-radius: 12px;
      background: #03CD3B;
      margin-right: 16px;
    }
    .pack-name {
      width: 177px;
      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .invoice-expires {
      width: 400px;
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .site-action {
    display: flex;
    button {
      height: 40px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0125em;
      padding: 0 1.6rem;
    }
    .wp-admin {
      margin-left: 1.6rem;
    }
  }
}
</style>