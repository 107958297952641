<template>
  <div>
    <FullScreenLoader v-if="showLoader" />
    <div v-if="availableToSetup.length > 0" class="websites container-small">
      <h6 class="section-title">Available to Setup</h6>
      <div class="website-list">
        <AvailableToSetupList
          v-for="invoice in availableToSetup"
          :key="invoice.invoice._id"
          :info="invoice"
          @setupWordPress="processSetupWordPress"
        />
      </div>
    </div>
    <div v-if="websites.length > 0" class="websites container-small">
      <h6 class="section-title">Websites</h6>
      <div class="website-list">
        <WebsiteList
          v-for="website in websites"
          :key="website._id"
          :website="website"
          @clearCache="clearCache"
          @wpAdminLogin="wpAdminLogin"
        />
      </div>
    </div>
    <div
      class="no-site container"
      v-if="availableToSetup.length === 0 && websites.length === 0"
    >
      <img src="../assets/img/no-data.svg" alt="No websites" />
      <p>No websites created yet</p>
      <router-link
        to="/site/create"
        tag="button"
        class="btn primary-btn create-new-big"
        >Create New Website</router-link
      >
    </div>

    <Modal
      v-if="setupWordPress.showModal"
      @close="setupWordPress.showModal = false"
    >
      <template slot="header">
        <h6>Setup WordPress</h6>
      </template>
      <div>
        <div class="input">
          <label for="site-name">Enter your site name</label>
          <input
            type="text"
            id="site-name"
            v-model="setupWordPress.websiteName"
            placeholder="E.g. My Portfolio"
          />
          <p v-if="$v.setupWordPress.websiteName.$error" class="input-error">
            Please enter a name for your website.
          </p>
        </div>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': setupWordPress.working }"
          classes="primary-btn"
          name="Setup WordPress"
          :show-done-sign="setupWordPress.done"
          :show-loader="setupWordPress.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="setupWordPressFromInvoice"
        ></Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
import { required } from "vuelidate/lib/validators";
import WebsiteList from "@/components/WebsiteList";
import AvailableToSetupList from "@/components/AvailableToSetupList";
import FullScreenLoader from "@/components/FullScreenLoader";
import Modal from "@/components/Modal";
import Button from "@/components/Button/Button";

export default {
  name: "DashHome",
  components: {
    WebsiteList,
    FullScreenLoader,
    AvailableToSetupList,
    Modal,
    Button,
  },
  data() {
    return {
      websites: [],
      showLoader: true,
      availableToSetup: [],
      setupWordPress: {
        working: false,
        done: false,
        showModal: false,
        invoiceId: "",
        packageId: "",
        websiteName: "",
      },
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    async getWebsites() {
      try {
        const res = await axios.get(`${process.env.VUE_APP_API_URL}/site`);
        const websites = res.data.websites;
        websites.forEach((w) => {
          w.wpAdminBtnConf = {
            working: false,
            done: false,
          };
          w.clearCacheBtnConf = {
            working: false,
            done: false,
          };
        });
        this.websites = websites;
        this.websites.forEach((w) => {
          if (w.status !== "active") this.getStatus(w._id);
        });
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
    },
    async wpAdminLogin(siteId) {
      const site = this.websites.find((w) => w._id === siteId);
      site.wpAdminBtnConf.working = true;
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/wp/${siteId}/generate-token`
        );
        const token = res.data.token;
        if (site.customDomainIsActive) {
          let proto = "http";
          if (site.sslCertificateIsActive) proto = "https";
          window.open(
            `${proto}://${site.customDomain}/wp-json/aurizor/auth?aurizor_magic_key=${token}&site_id=${siteId}`
          );
        } else {
          window.open(
            `https://${site.websiteUrl}/wp-json/aurizor/auth?aurizor_magic_key=${token}&site_id=${siteId}`
          );
        }
        site.wpAdminBtnConf.working = false;
        site.wpAdminBtnConf.done = true;
        setTimeout(() => {
          site.wpAdminBtnConf.done = false;
        }, 1000);
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
      site.wpAdminBtnConf.working = false;
    },
    async clearCache(siteId) {
      const site = this.websites.find((w) => w._id === siteId);
      site.clearCacheBtnConf.working = true;
      try {
        await axios.get(
          `${process.env.VUE_APP_API_URL}/wp/${siteId}/clear-cache`
        );
        site.clearCacheBtnConf.working = false;
        site.clearCacheBtnConf.done = true;
        setTimeout(() => {
          site.clearCacheBtnConf.done = false;
        }, 1000);
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
      site.clearCacheBtnConf.working = false;
    },
    async getAvailableInvoicesToSetup() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/user/available-to-setup`
        );
        this.availableToSetup = res.data.availableToSetup;
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
    },
    processSetupWordPress(info) {
      this.setupWordPress.invoiceId = info[0];
      this.setupWordPress.packageId = info[1];
      this.setupWordPress.showModal = true;
    },
    async setupWordPressFromInvoice() {
      if (!this.setupWordPress.working) {
        this.setupWordPress.working = true;

        // Check input field
        this.$v.setupWordPress.$touch();
        if (this.$v.setupWordPress.$error) {
          this.setupWordPress.working = false;
          return false;
        }

        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/site/new-site`, {
            userId: this.user._id,
            invoiceId: this.setupWordPress.invoiceId,
            packageId: this.setupWordPress.packageId,
            websiteNickName: this.setupWordPress.websiteName,
          });

          // Fetch new websites and available invoices
          await this.getAvailableInvoicesToSetup();
          await this.getWebsites();

          this.setupWordPress.working = false;
          this.setupWordPress.done = true;

          setTimeout(() => {
            this.setupWordPress.done = false;
            this.setupWordPress.showModal = false;
          }, 1000);
        } catch (err) {
          this.$toasted.error("Something went wrong");
        }

        this.setupWordPress.working = false;
      }
    },
    async getStatus(id) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_API_URL}/site/${id}`
        );
        const website = res.data.website;
        if (website.status === "active") {
          const websiteIndex = this.websites.findIndex((w) => (w._id = id));
          this.websites[websiteIndex].status = "active";
        } else {
          setTimeout(() => {
            this.getStatus(id);
          }, 500);
        }
      } catch (err) {
        setTimeout(() => {
          this.getStatus(id);
        }, 500);
      }
    },
    initFreshChat() {
      window.fcWidget.init({
        token: "ecc1c766-cbae-4ea0-922c-5cf6161ea37b",
        host: "https://wchat.freshchat.com",
        siteId: "AURIZOR",
        externalId: this.user._id,
        restoreId: this.user.freshChatRestoreId
          ? this.user.freshChatRestoreId
          : null,
        config: {
          headerProperty: {
            hideChatButton: false,
          },
        },
      });
      window.fcWidget.user.get((resp) => {
        const status = resp && resp.status;
        // const data = resp && resp.data
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: this.user.displayName,
            email: this.user.email,
            phone: this.user.phoneNumber,
            phoneCountryCode: `+880`,
          });
          window.fcWidget.on("user:created", async (resp) => {
            const status = resp && resp.status;
            const data = resp && resp.data;
            if (status === 200) {
              if (data.restoreId) {
                await axios.post(
                  `${process.env.VUE_APP_API_URL}/user/freshchat-restore-id`,
                  {
                    restoreId: data.restoreId,
                  }
                );
              }
            }
          });
        }
      });
    },
    showInfoFromQuery() {
      if (this.$route.query.message)
        this.$toasted.show(this.$route.query.message);
      if (this.$route.query.error) this.$toasted.error(this.$route.query.error);
    },
  },
  async created() {
    await this.getWebsites();
    await this.getAvailableInvoicesToSetup();
    setTimeout(() => {
      this.showLoader = false;
    }, 200);
    this.showInfoFromQuery();
    this.initFreshChat();
  },
  validations: {
    setupWordPress: {
      websiteName: { required },
    },
  },
};
</script>

<style lang="scss" scoped>
.websites {
  margin: 56px auto;

  .website-list {
    margin-top: 16px;
  }
}
.no-site {
  text-align: center;
  margin-top: 56px;
  padding-right: 40px;
  img {
    height: 150px;
  }
  p {
    font-size: 22px;
    margin-top: 16px;
  }
  .create-new-big {
    margin-top: 40px;
    padding: 0 32px;
    height: 56px;
    font-size: 16px;
  }
}
</style>
