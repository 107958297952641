import { render, staticRenderFns } from "./DashHome.vue?vue&type=template&id=2a2fb42f&scoped=true&"
import script from "./DashHome.vue?vue&type=script&lang=js&"
export * from "./DashHome.vue?vue&type=script&lang=js&"
import style0 from "./DashHome.vue?vue&type=style&index=0&id=2a2fb42f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a2fb42f",
  null
  
)

export default component.exports